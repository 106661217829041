import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CookieService } from '../services/cookie.service';
import { GlobalService } from '../services/global.service';
import { User } from '../models/auth.models';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    user: User;
    token:string = '';
    userDetails:any;
    constructor(
        private http: HttpClient, 
        private cookieService: CookieService,
        private global: GlobalService
        ) {
    }

    /**
     * Returns the current user
     */
    public currentUser(): User {
        if (!this.user) {
            this.user = JSON.parse(this.cookieService.getCookie('currentUser'));
            this.userDetails = this.user?.school ?? this.user?.project;
            this.token = this.cookieService.getCookie('accessToken');
        }
        return this.user;
    }

    /**
     * Performs the auth
     * @param email email of user
     * @param password password of user
     */
    getUser(userId: string) {
        return this.http.get<any>(`${this.global.API_URL}user/${userId}`)
            .pipe(map(data => {
                if (data) {
                    this.user = data.data;
                    this.userDetails = data.data.school;
                    this.cookieService.setCookie('currentUser', JSON.stringify(this.user), 1);
                }
                return this.user;
            }));
    }
    sendOTP(username: string) {
        const data = {
            username: username,
            role:"admin"
        }
        return this.http.post<any>(`${this.global.API_URL}public/auth/sendOTP`, data)
            .pipe(map(fetchData => {
                return fetchData;
            }));
    }

    verifyOTP(userId: string, otp:string) {
        const data = {
            userId: userId,
            otp: otp
        }
        return this.http.post<any>(`${this.global.API_URL}public/auth/verifyOTP`, data)
            .pipe(map(fetchData => {
                if (fetchData && fetchData.status == 200) {
                    this.token = fetchData.data.accessToken;
                    this.cookieService.setCookie('accessToken', this.token, 1);
                }
                // login successful if there's a jwt token in the response
                return fetchData;
            }));
    }

    /**
     * Logout the user
     */
    logout() {
        // remove user from local storage to log user out
        this.cookieService.deleteCookie('currentUser');
        this.user = null;
        this.userDetails = null;
    }
    isAdmin(){
        return this.user?.profile?.type?.includes('admin')?? false;
    }
    isEmployee(){
        return this.user?.profile?.type?.includes('employee')?? false;
    }
    isSchool(){
        return this.user?.profile?.type?.includes('school')?? false;
    }
    isProjectPortal(){
        return this.user?.profile?.type?.includes('project')?? false;
    }
    login(username:string, password:string) {
        let data:any = {
            user: username,
            password: password,
            role: 'admin'
        }
        return this.http.post<any>(`${this.global.API_URL}public/auth/login`, data)
            .pipe(map(fetchData => {
                if (fetchData && fetchData.status == 200) {
                    this.token = fetchData.data.accessToken;
                    this.cookieService.setCookie('accessToken', this.token, 1);
                }
                // login successful if there's a jwt token in the response
                return fetchData;
            }));
      }
}

