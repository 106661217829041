import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouterModule } from '@angular/router';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { SimplebarAngularModule } from 'simplebar-angular';

import { UIModule } from '../../shared/ui/ui.module';

import { SharedModule } from '../shared/shared.module';
import { LayoutComponent } from './layout/layout.component';
import { TopbarComponent } from './topbar/topbar.component';

@NgModule({
  declarations: [LayoutComponent, TopbarComponent],
  imports: [
    CommonModule, RouterModule, NgbDropdownModule,
    SimplebarAngularModule,
    UIModule, SharedModule
  ],
  exports: [LayoutComponent, TopbarComponent]
})
export class DetachedLayoutModule { }
