import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-ui-portlet',
  templateUrl: './portlet.component.html',
  styleUrls: ['./portlet.component.scss']
})
export class PortletComponent implements OnInit {

  isCollapsed: boolean;
  isRefreshRequired: boolean;
  isShowing: boolean;

  @Input() title: string;
  @Input() content: string;
  @Input() bodyBgClass: string;
  @Input() textClass: string;

  @Output() refreshContentRequested = new EventEmitter();
  @Output() portletClosed = new EventEmitter();

  constructor() { }

  ngOnInit() {
    this.isCollapsed = false;
    this.isRefreshRequired = false;
    this.isShowing = true;
  }

  /**
   * Close the portet
   */
  private closePortlet(): void {
    this.portletClosed.emit();
    this.isShowing = false;
  }

  /**
   * Refresh the content
   */
  private refreshContent(): void {
    this.refreshContentRequested.emit();

    this.isRefreshRequired = true;
    setTimeout(() => {
      this.isRefreshRequired = false;
    }, 2000);
  }

}
