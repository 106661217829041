<!-- vertical layout -->
<app-vertical-layout *ngIf="isVerticalLayoutRequested()" [isBoxed]="isBoxedRequested()"
    [sidebarTheme]="leftSidebarTheme" [sidebarType]="leftSidebarWidth" [demo]="configuredDemo"></app-vertical-layout>

<!-- horizontal layout -->
<app-horizontal-layout *ngIf="isHorizontalLayoutRequested()" [isBoxed]="isBoxedRequested()" [demo]="configuredDemo">
</app-horizontal-layout>

<!-- detached layout -->
<app-detached-layout *ngIf="isDetachedLayoutRequested()" [sidebarType]="leftSidebarWidth" [demo]="configuredDemo">
</app-detached-layout>

<!-- right sidebar-->
<app-right-sidebar></app-right-sidebar>