<!-- ========== Left Sidebar Start ========== -->
<div class="left-side-menu {{ navClasses }}" (clickOutside)="hideMenu()" [exclude]="'.open-left'">

   <!-- LOGO -->
   <a href="/" class="logo text-center logo-light" *ngIf="includeLogo">
     <span class="logo-lg">
       <img src="assets/images/logo.png" alt="" height="16">
     </span>
     <span class="logo-sm">
       <img src="assets/images/logo_sm.png" alt="" height="16">
     </span>
   </a>

   <!-- LOGO -->
   <a href="/" class="logo text-center logo-dark" *ngIf="includeLogo">
     <span class="logo-lg">
       <img src="assets/images/logo-dark.png" alt="" height="16">
     </span>
     <span class="logo-sm">
       <img src="assets/images/logo_sm_dark.png" alt="" height="16">
     </span>
   </a>


  <ngx-simplebar style="height: 100%;">
    <div id="left-side-menu-container">
      <!-- user profile -->
      <div class="leftbar-user" *ngIf="includeUserProfile && loggedInUser">
        <a href="javascript: void(0);">
          <img src="assets/images/users/def.png" alt="user-image" height="42" class="rounded-circle shadow-sm">
          <span class="leftbar-user-name">{{loggedInUser?.profile?.firstName | titlecase}} {{loggedInUser?.profile?.lastName |titlecase}}</span>
        </a>
      </div>

      <!--- Sidemenu -->
      <ul class="metismenu side-nav" id="side-menu" #sideMenu>
        <ng-container *ngFor="let item of menuItems">
          <ng-container *ngIf="authService.isAdmin() || (authService.isSchool() && item.showSchool) || (authService.isEmployee && item.showEmployee)">
            <li class="side-nav-title side-nav-item menu-title" *ngIf="item.isTitle">{{ item.label }}</li>

            <ng-container *ngIf="!item.isTitle">
  
              <!-- menu item without any child -->
              <ng-container *ngIf="!hasItems(item)">
                <ng-container
                  *ngTemplateOutlet="MenuItem;context:{item: item, className: 'side-nav-item', linkClassName: 'side-nav-link side-nav-link-ref'}">
                </ng-container>
              </ng-container>
  
              <!-- menu item with child -->
              <ng-container *ngIf="hasItems(item)">
                <ng-container
                  *ngTemplateOutlet="MenuItemWithChildren;context:{item: item, linkClassName: 'side-nav-link', subMenuClassNames: 'side-nav-second-level'}">
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
          
        </ng-container>
      </ul>


      <!-- end Help Box -->
      <!-- End Sidebar -->

      <div class="clearfix"></div>

    </div>
  </ngx-simplebar>
  <!-- Sidebar -left -->

</div>
<!-- Left Sidebar End -->


<!-- reusable templates -->
<ng-template #MenuItemWithChildren let-item="item" let-linkClassName="linkClassName"
  let-subMenuClassNames="subMenuClassNames">
  <li class="side-nav-item">
    <a href="javascript: void(0)" class="side-nav-link-ref {{linkClassName}}" aria-expanded="false">
      <i [ngClass]="item.icon" *ngIf="item.icon"></i>
      <span class="badge badge-{{item.badge.variant}} float-right font-size-13"
        *ngIf="item.badge">{{item.badge.text}}</span>
      <span> {{ item.label }}</span>
      <span class="menu-arrow" *ngIf="!item.badge"></span>
    </a>

    <ul class="{{subMenuClassNames}}" aria-expanded="false">
      <ng-container *ngFor="let child of item.children">
        <ng-container *ngIf="hasItems(child)">
          <ng-container
            *ngTemplateOutlet="MenuItemWithChildren;context:{item: child, linkClassName: 'side-nav-link-ref', subMenuClassNames: 'side-nav-third-level'}">
          </ng-container>
        </ng-container>

        <ng-container *ngIf="!hasItems(child)">
          <ng-container
            *ngTemplateOutlet="MenuItem;context:{item: child, className: '', linkClassName: 'side-nav-link-ref'}">
          </ng-container>
        </ng-container>
      </ng-container>
    </ul>
  </li>
</ng-template>

<ng-template #MenuItem let-item="item" let-className="className" let-linkClassName="linkClassName">
  <li class="{{className}}">
    <ng-container *ngTemplateOutlet="MenuItemLink;context:{item: item, className: linkClassName}"></ng-container>
  </li>
</ng-template>

<ng-template #MenuItemLink let-item="item" let-className="className">
  <a [routerLink]="item.link" class="{{className}}">
    <i [ngClass]="item.icon" *ngIf="item.icon"></i>
    <span class="badge badge-{{item.badge.variant}} float-right font-size-13"
      *ngIf="item.badge">{{item.badge.text}}</span>
    <span> {{ item.label }}</span>
  </a>
</ng-template>