import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { environment } from "../../../environments/environment";
import { DateTime} from 'luxon';
@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  noData = false;
  BASE_URL = `${environment.API_URL}`;
  API_URL = `${environment.API_URL}api/v1/`;
  TIMEZONE = "Asia/Kolkata";
  math = Math;
  hideSidebar = false;
  schoolMode = false;
  courseType = [
    {label: "Student", value:"student"},
    {label: "Professional", value:"professional"}
  ]
  classes = [
    "I",
    "II",
    "III",
    "IV",
    "V",
    "VI",
    "VII",
    "VIII",
    "IX",
    "X",
    "XI",
    "XII",
    "Bachelors",
    "Masters"
  ];
  public _loading$ = new BehaviorSubject<boolean>(false);
  get loading$() { return this._loading$.asObservable(); }

  public _Modalloading = new BehaviorSubject<boolean>(false);
  get modalLoading() { return this._Modalloading.asObservable(); }
  constructor(
    private toastr: ToastrService
  ) {
  }
  capitalize(s) {
    if (s && s.length > 1)
      return s[0].toUpperCase() + s.slice(1);
    else
      return s;
  }
  showToast(type, s, s2 = '') {
    if (type == 'success')
      this.toastr.success(s2, s);
    else
      this.toastr.error(s2, s);
  }
  getTimefromDate(d:string) {
    return (d.substr(11, 8));
  }
  getLocalTimefromISO(d:string) {
    let x = DateTime.fromISO(d).toFormat('HH:mm');
    return x;
  }
  formatDate(t:string) {
    let x = DateTime.fromISO(t).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY);
    return x;
  }
  formatDateTime(t:string) {
    if (!t || t == '')
      return 'NA';
    let x = DateTime.fromISO(t).toLocaleString(DateTime.DATE_MED) + ", " + DateTime.fromISO(t).toFormat('HH:mm') + " (" + DateTime.fromISO(t).toFormat('ZZZZ') + ")";
    return x;
  }
  csvJSON(csv,requiredHeaders = []){
    const lines=csv.split("\n");
    const result = [];
    let headers=lines[0].split(",");
    headers = headers.map((header)=>header.trim());
    if(requiredHeaders.length>0){
      let answer = true;
      for(let i=0;i<requiredHeaders.length;i++){
        if(!headers?.includes(requiredHeaders[i])){
          answer = false;
        }
      }
      if(answer === false){
        this.showToast('error','Required headers missing. Please check CSV file');
        return;
      }
    }
    for(var i=1;i<lines.length;i++){
        const obj = {};
        const finalLine = lines[i].trim();
        if(finalLine?.length>0){
          const currentline=lines[i].split(",");
          for(let j=0;j<headers.length;j++){
              obj[headers[j]] = currentline[j]?.trim();
          }
          result.push(obj);
        }
    }
    return (result); //JSON
  }
  jsonToCSV(json){
    const items = json
    const replacer = (key, value) => value === null ? '' : value 
    const header = Object.keys(items[0])
    const csv = [
      header.join(','),
      ...items.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','))
    ].join('\r\n');
    return csv??'';
  }
}
