<!-- Right Sidebar -->
<div class="right-bar" (clickOutside)="hide()" [exclude]="'.right-bar-toggle'">

  <div class="rightbar-title">
    <a href="javascript:void(0);" class="right-bar-toggle float-right" (click)="hide();">
      <i class="dripicons-cross noti-icon"></i>
    </a>
    <h5 class="m-0">Settings</h5>
  </div>

  <ngx-simplebar style="height: 100%">
    <div class="rightbar-content" id="right-bar-c">

      <div class="p-3">
        <div class="alert alert-warning" role="alert">
          <strong>Customize </strong> the navigation layout, sidebar menu, etc.
        </div>
      </div>

      <!-- Settings -->
      <h5 class="pl-3">Layout</h5>
      <hr class="mb-0" />

      <div class="p-3">
        <div class="custom-control custom-switch mb-1">
          <input type="radio" class="custom-control-input" name="layout" value="detached" id="detached-check"
            (change)="changeLayout('detached')" [ngModel]="layoutType" />
          <label class="custom-control-label" for="detached-check">Detached Layout</label>
        </div>
      </div>

      
      <h5 class="pl-3">Left Sidebar</h5>
      <hr class="mb-0" />

      <div class="p-3">
        
        <div class="custom-control custom-switch mb-1">
          <input type="radio" class="custom-control-input" name="compact" value="fixed" id="fixed-check"
            [disabled]="disabledisableSidebarWidth" (change)="changeLeftSidebarType('fixed')"
            [ngModel]="leftSidebarWidth" />
          <label class="custom-control-label" for="fixed-check">Fixed</label>
        </div>

        <div class="custom-control custom-switch mb-1">
          <input type="radio" class="custom-control-input" name="compact" value="condensed" id="condensed-check"
            [disabled]="disabledisableSidebarWidth" (change)="changeLeftSidebarType('condensed')"
            [ngModel]="leftSidebarWidth" />
          <label class="custom-control-label" for="condensed-check">Condensed</label>
        </div>

        <div class="custom-control custom-switch mb-1">
          <input type="radio" class="custom-control-input" name="compact" value="scrollable" id="scrollable-check"
            [disabled]="disabledisableSidebarWidth" (change)="changeLeftSidebarType('scrollable')"
            [ngModel]="leftSidebarWidth" />
          <label class="custom-control-label" for="scrollable-check">Scrollable</label>
        </div>
      </div>

    </div>
  </ngx-simplebar>
</div>

<div class="rightbar-overlay"></div>
<!-- /Right-bar -->