<!-- Topbar Start -->
<div class="navbar-custom topnav-navbar topnav-navbar-dark">
  <div class="container-fluid">

    <!-- LOGO -->
    <a routerLink="/" class="topnav-logo">
      <span class="topnav-logo-lg">
        <img src="assets/images/logo.png" alt="" height="40">
      </span>
      <span class="topnav-logo-sm">
        <img src="assets/images/logo_sm.png" alt="" height="40">
      </span>
    </a>

    <ul class="list-unstyled topbar-right-menu float-right mb-0">
      <li class="notification-list d-lg-block">
        <button class="nav-link dropdown-toggle arrow-none btn btn-link right-bar-toggle"
          (click)="toggleRightSidebar()">
          <i class="dripicons-gear noti-icon"></i>
        </button>
      </li>

      <li class="dropdown notification-list topbar-dropdown d-none d-lg-block" ngbDropdown>
        <a class="nav-link dropdown-toggle arrow-none" data-toggle="dropdown" href="javascript: void(0);" role="button"
          aria-haspopup="false" aria-expanded="false" ngbDropdownToggle id="langDropdown">
          <img src="{{ selectedLanguage.flag }}" alt="{{ selectedLanguage.name }}" class="mr-1" height="12"> <span
            class="align-middle">{{ selectedLanguage.name }}</span> <i
            class="mdi mdi-chevron-down ml-1 align-middle"></i>
        </a>
        <div class="dropdown-menu dropdown-menu-right topbar-dropdown-menu" aria-labelledby="langDropdown"
          ngbDropdownMenu>
          <!-- item-->
          <a *ngFor="let language of languages;" href="javascript:void(0);" class="dropdown-item notify-item"
            (click)="changeLanguage(language)" ngbDropdownItem>
            <img src="{{ language.flag }}" alt="{{ language.name }}" class="mr-1" height="12"> <span
              class="align-middle">{{ language.name }}</span>
          </a>
        </div>
      </li>

      <!-- <li class="dropdown notification-list" ngbDropdown>
        <a class="nav-link dropdown-toggle arrow-none" data-toggle="dropdown" href="javascript: void(0);" role="button"
          aria-haspopup="false" aria-expanded="false" ngbDropdownToggle id="notificationDropdown">
          <i class="dripicons-bell noti-icon"></i>
          <span class="noti-icon-badge"></span>
        </a>
        <div class="dropdown-menu dropdown-menu-rightdropdown-lg" aria-labelledby="notificationDropdown"
          ngbDropdownMenu>

          <div class="dropdown-item noti-title" ngbDropdownItem>
            <h5 class="m-0">
              <span class="float-right">
                <a href="javascript: void(0);" class="text-dark">
                  <small>Clear All</small>
                </a>
              </span>Notification
            </h5>
          </div>

          <ngx-simplebar style="height: 240px;">
            <div id="notification-items">
              <a *ngFor="let notification of notificationItems;" href="{{ notification.redirectTo }}"
                class="dropdown-item notify-item" ngbDropdownItem>
                <div class="notify-icon bg-{{ notification.bgColor }}">
                  <i class="{{ notification.icon }}"></i>
                </div>
                <p class="notify-details">{{ notification.text }}
                  <small class="text-muted">{{ notification.subText }}</small>
                </p>
              </a>
            </div>
          </ngx-simplebar>

          <a href="javascript:void(0);" class="dropdown-item text-center text-primary notify-item notify-all">
            View All
          </a>
        </div>
      </li> -->

      <li class="dropdown notification-list" ngbDropdown>
        <a class="nav-link dropdown-toggle nav-user arrow-none mr-0" data-toggle="dropdown" href="javascript: void(0);"
          role="button" aria-haspopup="false" aria-expanded="false" ngbDropdownToggle id="profileDropdown">
          <span class="account-user-avatar">
            <img src="assets/images/users/def.png" alt="user-image" class="rounded-circle">
          </span>
          <span>
            <span class="account-user-name">{{loggedInUser?.profile?.firstName | titlecase}} {{loggedInUser?.profile?.lastName |titlecase}}</span>
            <span class="account-position" *ngFor="let role of loggedInUser?.profile?.type">{{role | titlecase}}</span>
          </span>
        </a>
        <div class="dropdown-menu dropdown-menu-right topbar-dropdown-menu profile-dropdown"
          aria-labelledby="profileDropdown" ngbDropdownMenu>
          <!-- item-->
          <div class=" dropdown-header noti-title" ngbDropdownItem>
            <h6 class="text-overflow m-0">Welcome !</h6>
          </div>

          <!-- item-->
          <!-- <a href="javascript:void(0);" class="dropdown-item notify-item" ngbDropdownItem>
            <i class="mdi mdi-account-circle mr-1"></i>
            <span>My Account</span>
          </a> -->
          
          <!-- item-->
          <!-- <a href="javascript:void(0);" class="dropdown-item notify-item" ngbDropdownItem>
            <i class="mdi mdi-lifebuoy mr-1"></i>
            <span>Support</span>
          </a> -->

          <!-- item-->
          <a href="javascript:void(0);" class="dropdown-item notify-item" ngbDropdownItem (click)="logout()">
            <i class="mdi mdi-logout mr-1"></i>
            <span>Logout</span>
          </a>

        </div>
      </li>

    </ul>

    <a class="button-menu-mobile open-left disable-btn" (click)="toggleMobileMenu($event)">
      <div class="lines">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </a>

  </div>
</div>
<!-- end Topbar -->