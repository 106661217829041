import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { DetachedLayoutModule } from './detached/detached.module';

import { SharedModule } from './shared/shared.module';
import { LayoutContainerComponent } from './layout-container.component';


@NgModule({
  declarations: [LayoutContainerComponent],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    DetachedLayoutModule
  ]
})
export class LayoutsModule { }
