<!-- topbar -->
<app-detached-topbar (settingsButtonClicked)="onSettingsButtonClicked()"
  (mobileMenuButtonClicked)="onToggleMobileMenu()"></app-detached-topbar>

<div class="container-fluid">
  <div class="wrapper">
    <!-- left sidebar -->
    <app-left-sidebar *ngIf="!auth.isSchool() && !global.hideSidebar" includeUserProfile="true" navClasses="left-side-menu-detached" [isCondensed]="isCondensed"
      [isScrollable]="isScrollable"></app-left-sidebar>

    <div class="content-page">
      <div class="content">
        <!-- content-->
        <router-outlet></router-outlet>
      </div>

      <!-- footer-->
      <app-footer></app-footer>
    </div>
  </div>

</div>