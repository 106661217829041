import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent,HttpResponse, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { AuthenticationService } from '../services/auth.service';
import { GlobalService } from '../services/global.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private authenticationService: AuthenticationService,
        private global: GlobalService
        ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(tap(evt => {
            if (evt instanceof HttpResponse) {
                console.log(evt.body);
                if(evt.body && evt.body.error){
                    let y = '';
                    for (let x in evt.body.message) {
                        y = evt.body.message[x];
                        break;
                    }
                    console.log(y);
                    this.global.showToast('error', 'Error occured', y);
                }
            }
        }),
        catchError(err=>{
            if(err.status == 0)
                this.global.showToast('error','Internet Disconnected');
            return throwError(err);
        }));
    }
}
