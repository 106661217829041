import { Component, OnInit, AfterViewInit, Input, OnChanges } from '@angular/core';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { GlobalService } from 'src/app/core/services/global.service';

import { EventService } from '../../../core/services/event.service';
import { SIDEBAR_WIDTH_CONDENSED, SIDEBAR_WIDTH_SCROLLABLE } from '../../shared/models/layout.model';

@Component({
  selector: 'app-detached-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit, AfterViewInit, OnChanges {

  @Input() sidebarType: string;
  @Input() isCondensed: boolean;
  @Input() isScrollable: boolean;
  
  @Input() demo: string;

  isInitCompleted: boolean;

  constructor(
    private eventService: EventService,
    public auth: AuthenticationService,
    public global: GlobalService
    ) { }

  ngOnInit() {
    this.isInitCompleted = false;
  }

  ngAfterViewInit() {
    document.body.removeAttribute('data-layout-mode');
    document.body.setAttribute('data-layout', 'detached');
    this.isInitCompleted = true;
  }

  ngOnChanges() {
    if (this.isInitCompleted) {
      // left sidebar type
      switch (this.sidebarType) {
        case SIDEBAR_WIDTH_CONDENSED:
          document.body.setAttribute('data-leftbar-compact-mode', 'condensed');
          this.isCondensed = true;
          this.isScrollable = false;
          break;
        case SIDEBAR_WIDTH_SCROLLABLE:
          this.isScrollable = true;
          this.isCondensed = false;
          document.body.setAttribute('data-leftbar-compact-mode', 'scrollable');
          break;
        default:
          this.isCondensed = false;
          this.isScrollable = false;
          document.body.setAttribute('data-leftbar-compact-mode', 'fixed');
          break;
      }
    }
  }

  /**
   * on settings button clicked from topbar
   */
  onSettingsButtonClicked() {
    this.eventService.broadcast('showRightSideBar');
  }

  /**
   * On mobile toggle button clicked
   */
  onToggleMobileMenu() {
    document.body.classList.toggle('sidebar-enable');
  }
}
