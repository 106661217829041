import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-ui-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {
  title: string = '';
  text: string = '';
  payload:any;
  currentModal:NgbModalRef = null;
  @Output() confirm: EventEmitter<any> = new EventEmitter();
  @ViewChild('content', {static: true}) content: any;

  constructor(
      public activeModal: NgbModal,
  ) {
  }

  ngOnInit() {
  }

  /**
   * Opens the modal
   */
  
  open(data: any = false) {
      console.log(data);
      this.title = data.title;
      this.text = data.text;
      this.payload = data.payload;
      setTimeout(()=>{
        this.currentModal = this.activeModal.open(this.content, {backdrop: 'static',centered: true});
      },200);
  }

  /**
   * Saves the event
   */
  saveEvent(b:boolean) {
      let event = {e: b, payload: this.payload}
      this.confirm.emit(event);
      if(this.currentModal)
      this.currentModal.close();
  }
}
