<ng-template #t let-date let-focused="focused">
  <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)"
    [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date"
    (mouseleave)="hoveredDate = null">
    {{ date.day }}
  </span>
</ng-template>

<input type="text" class="form-control date" (click)="hidden=!hidden" name="daterange"
  autocomplete="off" [(ngModel)]="selected" />

<ngb-datepicker #dp (select)="onDateSelection($event)" [displayMonths]="2" [dayTemplate]="t" outsideDays="hidden"
  [hidden]="hidden">
</ngb-datepicker>