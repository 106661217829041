<!-- Add New Category MODAL -->
<ng-template #content let-c="close" let-d="dismiss">
    <div class="modal-header pr-3 pl-3 border-bottom-0 d-block">
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true"
                (click)="d('Cross click'); removeCtReturn()" >&times;
        </button>
        <h4 class="modal-title" id="modal-event-title">{{title}}</h4>
        
    </div>
    <div class="modal-body pt-2 pr-3 pl-3">
        <div class="row">
            <div class="col-12">
                <p>{{text}}</p>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col">
                <button class="btn btn-primary mr-2" (click) = "saveEvent(true)" type="button">Yes</button>
                <button class="btn btn-secondary" (click) = "saveEvent(false)" type="button">No</button>
            </div>
        </div>
    </div>
</ng-template>
