<div (clickOutside)="hide()" [exclude]="'.time-picker'">
  <div class="input-group" *ngIf="showInputGroupButton">
    <input type="text" class="form-control time time-picker" (click)="hidden=!hidden" name="daterange" autocomplete="off"
      [value]="getSelectedTime()" />

    <div class="input-group-append">
      <span class="input-group-text"><i class="dripicons-clock"></i></span>
    </div>
  </div>

  <input type="text" class="form-control time  time-picker" (click)="hidden=!hidden" name="daterange" autocomplete="off"
    [value]="getSelectedTime()" *ngIf="!showInputGroupButton" />

  <ngb-timepicker [(ngModel)]="selectedTime" [hourStep]="hourStep" [meridian]="meridian" [minuteStep]="minuteStep"
    [seconds]="seconds" [secondStep]="secondStep" [readonlyInputs]="readonlyInputs" [size]="size" [spinners]="spinners"
    [hidden]="hidden" class="time-picker">
  </ngb-timepicker>
</div>