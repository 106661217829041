import { Component, OnInit, Input, Output } from '@angular/core';
import { EventEmitter } from 'events';

@Component({
  selector: 'app-ui-timepicker',
  templateUrl: './timepicker.component.html',
  styleUrls: ['./timepicker.component.scss']
})
export class TimepickerComponent implements OnInit {

  selectedTime: any;
  hidden: boolean;

  @Input() hourStep: number;
  @Input() meridian: boolean;
  @Input() minuteStep: number;
  @Input() seconds: boolean;
  @Input() secondStep: number;
  @Input() readonlyInputs: boolean;
  @Input() size: string;
  @Input() spinners = true;

  @Input() showInputGroupButton = true;

  @Output() timeSelected = new EventEmitter();

  constructor() { }

  ngOnInit() {
    this.hidden = true;
    const current = new Date();
    this.selectedTime = { hour: current.getHours(), minute: current.getMinutes(), second: current.getSeconds() };
  }

  /**
   * Returns the two digit formatted number
   */
  _getTwoDigitNumber(num: number) {
    return num >= 10 ? num : '0' + num;
  }

  /**
   * Returns formatted selected time
   */
  getSelectedTime() {
    let formattedTime = this._getTwoDigitNumber(this.selectedTime.hour) + ':' + this._getTwoDigitNumber(this.selectedTime.minute);
    if (this.seconds) {
      formattedTime += ':' + this._getTwoDigitNumber(this.selectedTime.second);
    }

    if (this.meridian) {
      formattedTime += this.selectedTime.hour >= 12 ? ' PM' : ' AM';
    }
    return formattedTime;
  }

  /**
   * Hides the picker
   */
  hide() {
    this.hidden = true;
  }
}
