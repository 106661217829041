<!-- Portlet card -->
<div class="card {{ bodyBgClass}} {{ textClass }}" *ngIf="isShowing">
  <div class="card-body">
    <div class="card-widgets">
      <a href="javascript:void(0);" data-toggle="reload" (click)="refreshContent()">
        <i class="mdi mdi-refresh"></i>
      </a>
      <a data-toggle="collapse" href="javascript:void(0);" role="button" aria-expanded="false" aria-controls="cardCollpase" 
        (click)="isCollapsed = !isCollapsed" [ngClass]="{'collapsed': isCollapsed}">
        <i class="mdi mdi-minus"></i>
      </a>
      <a href="javascript:void(0);" data-toggle="remove" (click)="closePortlet()">
        <i class="mdi mdi-close"></i>
      </a>
    </div>
    <h5 class="card-title mb-0">{{ title }}</h5>

    <div id="cardCollpase" class="collapse pt-3 show" [ngbCollapse]="isCollapsed">
      {{ content }}
    </div>
  </div>

  <div class="card-disabled" *ngIf="isRefreshRequired">
    <div class="card-portlets-loader"></div>
  </div>
</div>
<!-- end card-->