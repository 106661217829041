import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from '../services/auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    schoolRoutes = [
        '/users'
    ]
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this.authenticationService.currentUser();
        if (currentUser) {
            if(this.authenticationService.isSchool()){
                for(let i=0;i<this.schoolRoutes.length;i++){
                    if(state.url.includes(this.schoolRoutes[i])){
                        console.log('yes')
                        return true;
                    }
                }
                if(state.url == '/')
                    return true;
                // return true;
                this.router.navigate(['/404']);
                return false;
            }
            return true;
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/account/login'], { queryParams: { returnUrl: state.url } });
        return false;
    }
}