import { MenuItem } from '../models/menu.model';

export const MENU: MenuItem[] = [
    {
        label: 'Navigation',
        isTitle: true,
        showEmployee:true,
        showSchool:true
    },
    {
        label: 'Dashboard',
        icon: 'uil-home-alt',
        link:'/'
    },
    {
        label: 'Courses',
        icon: 'uil-briefcase',
        children: [
            {
                label: 'List',
                link: '/courses'
            },
            {
                label: 'Create Course',
                link: '/courses/new',
                badge: {
                    variant: 'success-lighten badge-pill font-10',
                    text: 'New',
                },
            },
            {
                label: 'Create Level',
                link: '/courses/level/new',
                badge: {
                    variant: 'success-lighten badge-pill font-10',
                    text: 'New',
                },
            }
        ]
    },
    {
        label: 'Website',
        icon: 'uil-cog',
        children: [
            {
                label: 'Contact Details',
                link: '/website/contact'
            },
            {
                label: 'Social Links',
                link: '/website/social'
            },
            {
                label: 'Team',
                link: '/website/team'
            },
            {
                label: 'Media Fusion',
                children: [
                    {
                        label: 'General',
                        link: '/website/media-fusion'
                    },
                    {
                        label: 'Our Services',
                        link: '/website/media-fusion/our-services'
                    },
                    {
                        label: 'Testimonials',
                        link: '/website/media-fusion/testimonials'
                    },
                    {
                        label: 'Counters',
                        link: '/website/media-fusion/counters'
                    },
                    {
                        label: 'Partners',
                        link: '/website/media-fusion/partners'
                    },
                    {
                        label: 'Leads',
                        link: '/website/media-fusion/leads'
                    },
                ]
            }
        ]
    },
    {
        label: 'Users',
        link: '/users',
        icon: 'uil-users-alt',
        showEmployee:true,
        showSchool:true
    },
    {
        label: 'Schools',
        link: '/schools',
        icon: 'uil-building',
    },
    {
        label: 'Projects',
        link: '/project',
        icon: 'uil-briefcase',
    },
    {
        label: 'Coupons',
        link: '/coupons',
        icon: 'uil-receipt-alt',
    },
];
